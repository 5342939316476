@import "common_styles/scss/variables";
@import "common_styles/scss/fonts";
@import "common_styles/scss/mixins";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "../node_modules/bootstrap/scss/bootstrap";

@import "common_styles/scss/table";
@import "common_styles/scss/icons";
@import "common_styles/scss/icons2";
@import "common_styles/scss/template";
@import "common_styles/scss/template-embeded";
@import "common_styles/scss/template-front-embeded";
@import "common_styles/scss/loading";
@import "common_styles/scss/animate";
@import "common_styles/scss/frame-front";
@import "common_styles/scss/panel";
@import "common_styles/scss/select2_overrides";
@import "common_styles/scss/indents";
@import "common_styles/scss/tabs";
@import "common_styles/scss/yandex_map";
@import "common_styles/scss/bootstrap_override";
@import "common_styles/scss/lightbox";
@import "common_styles/scss/dsg-autocomplete";
@import "common_styles/scss/tooltip";
@import "common_styles/scss/embeded";
@import "common_styles/scss/osm/osm";

.header__logos{
  display: block!important;
}

body{
  min-height: 0!important;
}
