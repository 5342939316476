.card{
  background: $panel-color-background;
  border: solid $panel-border-width $panel-color-border;
  border-radius: $panel-border-radius;
  margin-bottom: 2.5rem;

  .card-block{
    padding: 1.6rem;
  }

  .card-footer{
  }
}
