@import "../../common_styles/scss/variables";
.tooltip-wrapper{
  display: block;
}

.app-tooltip {
  display: block;
  max-width: 200px;
  color: black;
  text-align: center;
  padding: 5px 8px;
  position: absolute;
  z-index: 101;
  opacity: 0;
  -webkit-transition: opacity 100ms;
  -moz-transition: opacity 100ms;
  -o-transition: opacity 100ms;
  transition: opacity 100ms;
  font-size: 0.8rem;
  line-height: 1.2rem;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.app-tooltip.app-tooltip-show {
  opacity: 1;
}

.app-tooltip::after {
  content: "";
  position: absolute;
  border-style: solid;
}
.app-tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
}

.app-tooltip.app-tooltip-top {
  &::before{
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
  }
  &::after{
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: #f7f7f7 transparent transparent transparent;
  }
}

.app-tooltip.app-tooltip-bottom {
  &::before{
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-color: transparent transparent rgba(0, 0, 0, 0.2) transparent;
  }
  &::after{
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent #f7f7f7 transparent;
  }
}

.app-tooltip.app-tooltip-left {
  &::before{
    top: 50%;
    left: 100%;
    margin-top: -6px;
    border-width: 6px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.2);
  }
  &::after{
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent #f7f7f7;
  }
}

.app-tooltip.app-tooltip-right::after {
  &::before{
    top: 50%;
    right: 100%;
    margin-top: -6px;
    border-width: 6px;
    border-color: transparent rgba(0, 0, 0, 0.2) transparent transparent;
  }
  &::after{
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent #f7f7f7 transparent transparent;
  }
}


.app-tooltip-kvartirogramma {
  display: block;
  padding: 4px 4px 7px 4px;
  position: absolute;
  z-index: 101;
  opacity: 0;
  -webkit-transition: opacity 100ms;
  -moz-transition: opacity 100ms;
  -o-transition: opacity 100ms;
  transition: opacity 100ms;
  font-size: 0.8rem;
  line-height: 1.2rem;
  background-clip: padding-box;
  border: 1px solid $border-gray-color;
  border-radius: 8px;
  background-color: #FAFAFA;
}

.app-tooltip-kvartirogramma.app-tooltip-show {
  opacity: 1;
}
