@import "./variables";

%icon{
  font-family: $font-family-icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  @extend %icon;

  /* Modifier */
  &_sm{
    @extend %icon;
    font-size: .6rem!important;
    padding-top: 7px!important;
  }
  &_big{
    @extend %icon;
    font-size: 1.125rem!important;
  }
  &_md{
    font-size: 1.125rem;
  }
  &_line{
    position: relative;
    top: 3px;
  }
  &_grey:before{
    color: $c-grey2 !important;
  }


  /* Front icons */
  $icons_front: (
    back: "\e91a",
    surface: "\e918",
    rooms_alt: "\e919",
    rooms: "\ee917",
    number: "\ee916",
    price_rouble: "\ee915",
    chevron_down: "\e900",
    Caret2: "\e900",
    action_log: "\e90f",
    ActionLog: "\e90f",
    branding: "\e98c",
    done_mark: "\e912",
    client_mode_on: "\e910",
    client_mode_off: "\e911",
    Commlog: "\e901",
    icons8-calendar: "\e902",
    icons8-dashboard: "\e903",
    icons8-key_exchange: "\e904",
    icons8-keyhole_shield: "\e905",
    icons8-logout_rounded_up: "\e906",
    icons8-online_support: "\e907",
    icons8-permanent_job: "\e908",
    icons8-settings: "\e909",
    Previews: "\e90a",
    Queve: "\e90b",
    Reserves: "\e90c",
    Tariffs: "\e90d",
    Users: "\e90e",
    ad_module: "\e961",
    new-api: "\e974",
    new-hot: "\e975",
    new-hot-white: "\e97d",
    new-main: "\e976",
    new-menu-expand: "\e977",
    new-menu-collapse: "\e97e",
    new-mortgage: "\e978",
    new-reserves: "\e979",
    new-sort-down: "\e90e",
    new-sort-none: "\e910",
    new-sort-up: "\e90f",
    media-fb: "\e97f",
    media-logger: "\e980",
    media-vk: "\e981",
    new-help-active: "\e982",
    new-help-graphics: "\e983",
    new-help: "\e984",
    calendar-new: "\e985",
    integrations-new: "\e986",
    organisation-new: "\e987",
    hoisung-editor: "\e988",
    favorite-star: "\e98a",
    rs-logo: "\e955",
  );

  @each $name, $value in $icons_front {
    &_#{$name}:before{
      content: $value;
    }
  }

  &_download {
    &:before{
      content: "\e96a";
      color: $clr-red-dark;
    }
  }

  &_new-help {
    background: $clr-red;
    color: #fff;
    font-size: 1.3rem;
    padding: 2px;
    border-radius: 100%;
  }

  &_download_old {
    &:before{
      content: "\e913";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e914";
      color: $clr_white;
      margin-left: -1em;
    }
  }
  &_new-sort-none{
    color: $clr-red-dark;
    &:before {
      font-family: 'Icons2' !important;
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }
  &_new-sort-down{
    color: $clr-red-dark;
    &:before {
      font-family: 'Icons2' !important;
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }
  &_new-sort-up{
    color: $clr-red-dark;
    &:before {
      font-family: 'Icons2' !important;
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }

  &_rs-logo {
    @media (min-width: 769px) {
      line-height: 43px !important;
    }

    &:before {
      font-size: 1.25rem;
    }
    &:not(.menu__item-icon):before {
      color: #de1d25;
      font-size: 1.6rem;
      @include media-breakpoint-down(sm) {
        color: #ffffff;
        font-size: 0.77rem;
      }
    }
  }
  &_Integrations-Icon:before {
    content: "\e968";
  }
  &_Iframe-icon:before {
    content: "\e967";
    color: $clr-red-darker;
  }
  &_xml-icon:before {
    content: "\e969";
    color: $clr-red-dark;
  }
  &_media-vk:before {
    color: #0288d1;
  }
  &_media-logger:before {
    color: #ff6f00;
  }
  &_media-fb:before {
    color: #3f51b5;
  }

  /* CRM icons */
  $icons_crm: (
    fit_to_width: "\e91e",
    login_rounded_right: "\e921",
    comments: "\e926",
    chevron_right_round: "\e91d",
    delete_sign: "\e91c",
    menu: "\e92a",
    star-hover: "\e92c",
    star-simple: "\e92b",
    synchronize: "\e92d",
  );

  @each $name, $value in $icons_crm {
    &_#{$name}:before{
      content: $value;
    }
  }

  &_past {
    &:before{
      content: "\e924";
    }
    &:after{
      content: "\e925";
      margin-left: -0.9228515625em;
    }
  }
  &_Feed:before {
    content: "\e964";
    color: $clr-red-dark;
  }
  &_chevron_left_round:before {
    content: "\e91d";
    transform: rotate(540deg);
    display: inline-block;
  }
  &_star-active:before {
    content: "\e92e";
    color: $clr-yellow;
  }
  &_star-set {
    &:before{
      content: "\e92e";
    }
    &:after{
      content: "\e92f";
      margin-left: -1.0830078125em;
    }
  }
  &_chevron_divider {
    display: inline-block;
    height: 2px;
    width: 11px;
    background: #000;
  }


  /* Design2 icons */
  &_PDF:before {
    content: "\e966";
    color: $clr-red-dark;
  }
  &_Presentation-Doc:before {
    content: "\e965";
    color: $clr-red-dark;
  }
  &_Large_Star_Off:before {
    content: "\e930";
    color: #f9f9f9;
  }
  &_Large_Star_On {
    &:before{
      content: "\e931";
      color: rgb(249, 249, 249);
    }
    &:after{
      content: "\e932";
      margin-left: -1.5em;
      color: $clr-yellow;
    }
  }
  &_New-Something:before {
    content: "\e963";
    color: $clr-red-dark;
  }
  &_Large_Window_Close:before {
    content: "\e933";
    color: #fff;
  }
  &_Large_Window_Return:before {
    content: "\e934";
    color: #fff;
  }
  &_Small_Bonus {
    &:before{
      content: "\e936";
      color: $clr-red-dark;
      border: 1px solid rgba(0, 0, 0, 0.553);
      border-radius: 50%;
    }
    &:after{
      content: "\e937";
      margin-left: -1.05em;
      color: $clr-red-dark;
    }
  }
  &_Small_Broker-Tour:before {
    content: "\e938";
    color: #417505;
  }
  &_Small_Calendar {
    &:before{
      content: "\e939";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e93a";
      margin-left: -1em;
      color: rgb(0, 0, 0);
      opacity: 0.553;
    }
  }
  &_Small_Chevron-Down-Copy {
    &:before{
      content: "\e93b";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e93c";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
  &_Small_Chevron-Down {
    &:before{
      content: "\e93d";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e93e";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
  &_Small_Close:before {
    content: "\e93f";
    color: $clr-red-dark;
  }
  &_Small_Crowd:before {
    content: "\e940";
    color: $clr-red-dark;
  }
  &_Small_Dropdown {
    &:before{
      content: "\e943";
      color: $clr-red-dark;
      border: 1px solid $border-gray-color;
      border-radius: 50%;
    }
  }
  &_Small_Plus {
    &:before{
      content: "+";
      color: $clr-red-dark;
      border: 1px solid $border-gray-color;
      border-radius: 50%;
      font-weight: bold;
      width: 1.1rem;
      height: 1.1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      padding-bottom: 0.3rem;
    }
  }
  &_Small_Dropdown_Back {
    &:before{
      content: "\e943";
      color: #000;
      border: 2px solid #000;
      border-radius: 50%;
    }
    transform: rotate(90deg);
    display: inline-block;
  }
  &_Small_Dropdown_White {
    &:before{
      content: "\e943";
      color: #fff;
      border: 2px solid #fff;
      border-radius: 50%;
      font-size: 1.5rem;
    }
    transform: rotate(90deg);
    display: inline-block;
  }
  &_Small_Dropdown_Red {
    &:before{
      content: "\e943";
      color: $clr-red-dark;
      border: 1px solid $clr-red-dark;
      border-radius: 50%;
      line-height: 1.2rem;
    }
  }
  &_Small_Edit {
    &:before{
      content: "\e944";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e945";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
  &_Small_Edit_White {
    &:before{
      content: "\e944";
      color: #fff;
    }
    &:after{
      content: "\e945";
      margin-left: -1em;
      color: $clr-red-dark;
    }
  }
  &_Small_Close_White {
    &:before{
      content: "\e944";
      color: #fff;
    }
    &:after{
      content: "\e93f";
      margin-left: -1em;
      color: $clr-red-dark;
      font-size: 1rem;
      position: relative;
      top: -2px;
      left: -2px;
    }
  }
  &_Small_Finishing {
    &:before{
      content: "\e946";
      color: rgb(0, 0, 0);
      opacity: 0.553;
    }
    &:after{
      content: "\e947";
      margin-left: -1em;
      color: $clr-red-dark;
    }
  }
  &_Small_Gift {
    &:before{
      content: "\e948";
      color: rgb(0, 0, 0);
      opacity: 0.553;
    }
    &:after{
      content: "\e949";
      margin-left: -1em;
      color: $clr-red-dark;
    }
  }
  &_Small_Info-Tour:before {
    content: "\e94a";
    color: #c07e12;
  }
  &_Small_Lesson:before {
    content: "\e94b";
    color: #4a90e2;
  }
  &_Small_Message_Bad:before {
    content: "\e94c";
    color: $clr-red-dark;
  }
  &_Small_Message_Good:before {
    content: "\e94d";
    color: #7ed321;
  }
  &_Small_More {
    position: relative;
    &:before{
      content: "\e94e";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e94f";
      margin-left: -1.5em;
      color: rgb(255, 255, 255);
    }
    span{
      position: absolute;
      left: 0;
      &:before{
        content: "\e950";
        color: rgb(255, 255, 255);
      }
      &:after{
        content: "\e951";
        margin-left: -1em;
        color: rgb(255, 255, 255);
      }
    }
  }
  &_Small_Mortgage {
    &:before{
      content: "\e952";
      color: #000;
      opacity: 0.553;
    }
    &:after{
      content: "\e962";
      color: #df1e25;
      margin-left: -1em;
    }
  }
  &_Small_Next {
    &:before{
      content: "\e953";
      color: $clr-red-dark;
    }
    &:after{
      content: "\e954";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
  &_Small_None:before {
    content: ' ';
    background-image: url("../../assets_common/images/None.svg");
    width: 18px;
    height: 18px;
    display: block;
  }
  &_Small_Presentation:before {
    content: "\e956";
  }
  &_Small_Webinar:before {
    content: "\e956";
    color: #7ED321;
  }
  &_Small_Search:before{
    content: ' ';
    background-image: url("../../assets_common/images/Search.svg");
    width: 18px;
    height: 18px;
    display: block;
    margin-top: -5px;
  }
  &_Small_Table-Sorter_Down {
    &:before {
      content: "\e95a";
      color: $clr-red-dark;
    }
    &:after {
      content: "\e95b";
      margin-left: -1em;
      color: rgb(216, 215, 215);
    }
  }
  &_Small_Table-Sorter_Neutral:before {
    content: "\e95c";
    color: #d8d7d7;
  }
  &_Small_Table-Sorter_Up {
    &:before{
      content: "\e95d";
      color: rgb(216, 215, 215);
    }
    &:after{
      content: "\e95e";
          margin-left: -1em;
      color: $clr-red-dark;
    }
  }
  &_Small_Time:before {
    content: "\e95f";
    color: $clr-red-dark;
  }
  &_lampochka:before {
    content: "\e96d";
    color: #fff;
  }
  &_Sale-On:before {
    content: "\e96e";
    color: $clr-red-darker;
  }
  &_logo-youtube {
    &:before{
      content: "\e96b";
      color: #f00;
    }
    &:after{
      content: "\e96c";
      color: #fff;
      margin-left: -1.4365234375em;
    }
  }

  /* Theme CRM */
  &_triggered{
    &:before{
      color: #ffb217!important;
    }
    &:after{
      color: #ffffff!important;
    }
  }

  &_theme_crm{
    &.icon_star-simple{
      &:before{
        color: #3F3E3E;
      }
      &:hover:before{
        content: "\e92e";
        color: rgb(121, 85, 12);
      }
      &:hover:after{
        content: "\e92f";
        margin-left: -1.0830078125em;
        color: $clr-yellow;
      }
      &.icon_disabled:before,
      &[disabled]:before,
      &[readonly]:before{
        color: $clr-yellow;
      }
    }
    &.icon_fit_to_width{
      &:before{
        color: #fbfbfb;
      }
      &:hover:before{
        color: $clr-yellow;
      }
    }
    &.icon_login_rounded_right{
      &:before{
        color: #fbfbfb;
      }
      &:hover:before{
        color: $clr-yellow;
      }
    }
    &.icon_menu{
      &:before{
        color: #fbfbfb;
      }
      &:hover:before{
        color: $clr-yellow;
      }
    }
    &.icon_icons8-keyhole_shield{
      &:before{
        color: #fbfbfb;
      }
      &:hover:before{
        color: $clr-yellow;
      }
    }
    &.icon_comments{
      &:before{
        color: #fff;
      }
      &:hover:before{
        color: $clr-yellow;
      }
      &.icon_disabled:before,
      &[disabled]:before,
      &[readonly]:before{
        color: #BABABA;
        cursor: not-allowed;
      }
    }
    &.icon_chevron_right_round{
      &:before{
        color: #3F3E3E;
      }
      &:hover:before{
        color: $clr-red-dark;
      }
      &.icon_disabled:before,
      &[disabled]:before,
      &[readonly]:before{
        color: #BABABA;
        cursor: not-allowed;
      }
    }
    &.icon_chevron_left_round{
      &:before{
        color: #3F3E3E;
      }
      &:hover:before{
        color: $clr-red-dark;
      }
      &.icon_disabled:before,
      &[disabled]:before,
      &[readonly]:before{
        color: #BABABA;
        cursor: not-allowed;
      }
    }
    &.icon_delete_sign{
      &:before{
        color: #3F3E3E;
      }
      &:hover:before{
        color: $clr-red-dark;
      }
    }
    &.icon_synchronize{
      &:before{
        color: #3F3E3E;
      }
      &:hover:before{
        color: $clr-red-dark;
      }
    }
    &.icon_past{
      &:before{
        color: rgb(255, 255, 255);
      }
      &:after{
        color: $clr-yellow;
      }
      &:hover:before{
        color: $clr-yellow;
      }
      &:hover:after{
        color: rgb(251, 251, 251);
      }
    }
  }
}

/* Icons-housing */
$icons_housing: (
  building: "\e96f",
  plus-square: "\e970",
  pencil-round: "\e971",
  remove-round: "\e972",
  duplicate: "\e973",
);

.icon-housing {
  @each $name, $value in $icons_housing {
    &_#{$name}:before{
      @extend %icon;
      content: $value;
    }
  }
}
