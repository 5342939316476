@import "variables";

%square{
  content: "";
  display: block;
  position: absolute;
  width: 0.1875rem;
  height: 0.1875rem;
  background: rgba(0,0,0,.75);
  bottom: 0.1875rem;
  z-index: 2;
}

.navigation{
  display: flex;
  flex-direction: column;

  &_vertical{
    margin-top: 0.5rem;
    flex-direction: row;
    align-items: flex-start;

    .navigation__tabs{
      flex-direction: column;
      flex-basis: 20%;
      margin-right: 2rem;

      &:after {
        width: 1px;
        height: 100%;
        bottom: 0;
        left: unset;
        right: 0;
        z-index: 0;
      }
    }

    .navigation__tab{
      white-space: normal !important;

      &:after{
        content: none !important;
      }

      &__link{
        margin: 0 !important;

        &:before {
          width: 0.1875rem;
          height: 0.1875rem;
          top: 0;
          right: 0;
          left: auto !important
        }

        &:after {
          bottom: 0 !important;
        }

        &_active__slide {
          width: 0.1875rem !important;
          height: 100% !important;
          right: 0 !important;
          left: auto !important;
          bottom: 0 !important;
        }
      }
    }


    .navigation__tab__content{
      flex-grow: 1;
    }
  }
}
.navigation__tabs{
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-end;

  &:after{
    position: absolute;
    content: "";
    display: block;
    height: 1px;
    bottom: 0;
    background: $c-grey2;
    width: 100%;
    z-index: 1;
    left: 0;
  }

  .navigation__tab{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    //&:after {
    //  position: absolute;
    //  content: "";
    //  display: block;
    //  height: 0.1875rem;
    //  bottom: 0;
    //  background: rgba(0, 0, 0, .75);
    //  width: 100%;
    //  z-index: 1;
    //  left: 0;
    //}

    &:first-child{
      .navigation__tab__link {
        //&:before {
        //  @extend %square;
        //  left: 0;
        //}
      }
    }

    &_right{
      flex: 1 1 auto;
    }

    &__link{
      color: $c-dark;
      display: block;
      position: relative;
      padding: 0.7rem 0.55rem;
      text-align: right;
      margin: 0 0.2rem;
      font-size: 1rem;

      //&:after{
      //  @extend %square;
      //  right: 0;
      //}

      &:hover{
        text-decoration: none;

        .navigation__tab__link_active__slide{
          position: absolute;
          display: none;
          height: 0.1875rem;
          background: $tab-hovered-background;
          width: 100%;
          bottom: 1px;
          z-index: 1;
          left: 0;

        }
        .navigation__tab__link_active__slide{
          display: block;
        }
      }

      &_active{
        font-weight: normal;
        &:hover{
          .navigation__tab__link_active__slide{
            background: $tab-active-background;
          }
        }

        &__slide{
          position: absolute;
          display: none;
          height: 0.1875rem;
          background: $clr-red-dark;
          width: 100%;
          bottom: 1px;
          z-index: 1;
          left: 0;

        }
        .navigation__tab__link_active__slide{
          display: block;
        }
      }

      &_disabled{
        color: $c-dark-grey;
        &:hover{
          text-decoration: none;
          cursor: not-allowed;
        }
      }

      &__fixation {
        //text-decoration: underline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
