@import "../../common_styles/scss/variables";

@function rem($px) {
  @return ($px/$rem)+rem;
}


@mixin device-sm{
  @media (max-width: $width-sm){
    @content;
  }
}

@mixin device-md{
  @media (max-width: $width-md){
    @content;
  }
  @media (max-height: $height-md) {
    @content;
  }
}



@mixin media-md{
  @media (max-width: map-get($grid-breakpoints, 'md')){
    @content;
  }
}

@mixin media-lg{
  @media (max-width: map-get($grid-breakpoints, 'lg')){
    @content;
  }
}

@mixin media-xl{
  @media (max-width: map-get($grid-breakpoints, 'xl')){
    @content;
  }
}
