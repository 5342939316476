.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
  background-color: $c-red2;
}
.marker-cluster-small div {
  background-color: $c-red2;
}

.marker-cluster-medium {
  background-color: $c-red2;
}
.marker-cluster-medium div {
  background-color: $c-red2;
}

.marker-cluster-large {
  background-color: $c-red2;
}
.marker-cluster-large div {
  background-color: $c-red2;
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: $c-red2;
}
.leaflet-oldie .marker-cluster-small div {
  background-color: $c-red2;
}

.leaflet-oldie .marker-cluster-medium {
  background-color: $c-red2;
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: $c-red2;
}

.leaflet-oldie .marker-cluster-large {
  background-color: $c-red2;
}
.leaflet-oldie .marker-cluster-large div {
  background-color: $c-red2;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  color: $c-white;

  text-align: center;
  border-radius: 15px;
  font-size: 1rem;
}
.marker-cluster span {
  line-height: 30px;
}
