@import "variables";

.custom-control {
  input {
    &+.custom-control-indicator {
      background-color: $c-normal!important;
    }

    &[disabled]+.custom-control-indicator {
      background: $c-grey!important;
      cursor: not-allowed;
    }

    &:focus+.custom-control-indicator {
      box-shadow: none;
      outline: 0;
    }
  }
}
