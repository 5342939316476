$icn-left: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI1Ljg3NSAxLjc1QzM5LjE5ODkgMS43NSA1MCAxMi41NTExIDUwIDI1Ljg3NUM1MCAzOS4xOTg5IDM5LjE5ODkgNTAgMjUuODc1IDUwQzEyLjU1MTEgNTAgMS43NSAzOS4xOTg5IDEuNzUgMjUuODc1QzEuNzUgMTIuNTUxMSAxMi41NTExIDEuNzUgMjUuODc1IDEuNzVaTTUxLjc1IDI1Ljg3NUM1MS43NSAxMS41ODQ2IDQwLjE2NTQgMCAyNS44NzUgMEMxMS41ODQ2IDAgMCAxMS41ODQ2IDAgMjUuODc1QzAgNDAuMTY1NCAxMS41ODQ2IDUxLjc1IDI1Ljg3NSA1MS43NUM0MC4xNjU0IDUxLjc1IDUxLjc1IDQwLjE2NTQgNTEuNzUgMjUuODc1Wk0zMC41MDQ1IDE0LjI2NzNDMzAuMTY4OSAxMy45MTk2IDI5LjYxNSAxMy45MDk4IDI5LjI2NzMgMTQuMjQ1NUwxOC4yNjczIDI0Ljg2NDJDMTguMDk2NSAyNS4wMjkxIDE4IDI1LjI1NjMgMTggMjUuNDkzN0MxOCAyNS43MzExIDE4LjA5NjUgMjUuOTU4NCAxOC4yNjczIDI2LjEyMzJMMjkuMjY3MyAzNi43NDJDMjkuNjE1IDM3LjA3NzYgMzAuMTY4OSAzNy4wNjc4IDMwLjUwNDUgMzYuNzIwMUMzMC44NDAyIDM2LjM3MjUgMzAuODMwNCAzNS44MTg1IDMwLjQ4MjcgMzUuNDgyOUwyMC4xMzQ4IDI1LjQ5MzdMMzAuNDgyNyAxNS41MDQ1QzMwLjgzMDQgMTUuMTY4OSAzMC44NDAyIDE0LjYxNSAzMC41MDQ1IDE0LjI2NzNaIiBmaWxsPSIjREYzMTMzIi8+PC9zdmc+);
$icn-right: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI1Ljg3NSAxLjc1QzEyLjU1MTEgMS43NSAxLjc1IDEyLjU1MTEgMS43NSAyNS44NzVDMS43NSAzOS4xOTg5IDEyLjU1MTEgNTAgMjUuODc1IDUwQzM5LjE5ODkgNTAgNTAgMzkuMTk4OSA1MCAyNS44NzVDNTAgMTIuNTUxMSAzOS4xOTg5IDEuNzUgMjUuODc1IDEuNzVaTTAgMjUuODc1QzAgMTEuNTg0NiAxMS41ODQ2IDAgMjUuODc1IDBDNDAuMTY1NCAwIDUxLjc1IDExLjU4NDYgNTEuNzUgMjUuODc1QzUxLjc1IDQwLjE2NTQgNDAuMTY1NCA1MS43NSAyNS44NzUgNTEuNzVDMTEuNTg0NiA1MS43NSAwIDQwLjE2NTQgMCAyNS44NzVaTTIxLjI0NTUgMTQuMjY3M0MyMS41ODExIDEzLjkxOTYgMjIuMTM1IDEzLjkwOTggMjIuNDgyNyAxNC4yNDU1TDMzLjQ4MjcgMjQuODY0MkMzMy42NTM1IDI1LjAyOTEgMzMuNzUgMjUuMjU2MyAzMy43NSAyNS40OTM3QzMzLjc1IDI1LjczMTEgMzMuNjUzNSAyNS45NTg0IDMzLjQ4MjcgMjYuMTIzMkwyMi40ODI3IDM2Ljc0MkMyMi4xMzUgMzcuMDc3NiAyMS41ODExIDM3LjA2NzggMjEuMjQ1NSAzNi43MjAxQzIwLjkwOTggMzYuMzcyNSAyMC45MTk2IDM1LjgxODUgMjEuMjY3MyAzNS40ODI5TDMxLjYxNTIgMjUuNDkzN0wyMS4yNjczIDE1LjUwNDVDMjAuOTE5NiAxNS4xNjg5IDIwLjkwOTggMTQuNjE1IDIxLjI0NTUgMTQuMjY3M1oiIGZpbGw9IiNERjMxMzMiLz48L3N2Zz4=);
$icn-cross: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMTAuMzc1QzAgNC42NDUwNSA0LjY0NTA1IDAgMTAuMzc1IDBDMTYuMTA1IDAgMjAuNzUgNC42NDUwNSAyMC43NSAxMC4zNzVDMjAuNzUgMTYuMTA1IDE2LjEwNSAyMC43NSAxMC4zNzUgMjAuNzVDNC42NDUwNSAyMC43NSAwIDE2LjEwNSAwIDEwLjM3NVpNMTAuMzc1IDEuNDI2MzNDNS40MzI3OSAxLjQyNjMzIDEuNDI2MzMgNS40MzI3OSAxLjQyNjMzIDEwLjM3NUMxLjQyNjMzIDE1LjMxNzIgNS40MzI3OSAxOS4zMjM3IDEwLjM3NSAxOS4zMjM3QzE1LjMxNzIgMTkuMzIzNyAxOS4zMjM3IDE1LjMxNzIgMTkuMzIzNyAxMC4zNzVDMTkuMzIzNyA1LjQzMjc5IDE1LjMxNzIgMS40MjYzMyAxMC4zNzUgMS40MjYzM1pNNS42MTA2OSA1LjYyODQ4QzUuODg0MjUgNS4zNDUxIDYuMzM1NzMgNS4zMzcxNCA2LjYxOTEgNS42MTA2OUwxMC4zNzUgOS4yMzY0TDE0LjEzMDkgNS42MTA2OUMxNC40MTQzIDUuMzM3MTQgMTQuODY1OCA1LjM0NTEgMTUuMTM5MyA1LjYyODQ4QzE1LjQxMjkgNS45MTE4NiAxNS40MDQ5IDYuMzYzMzQgMTUuMTIxNSA2LjYzNjg5TDExLjQwMTggMTAuMjI3NkwxNS4xMjE1IDEzLjgxODRDMTUuNDA0OSAxNC4wOTIgMTUuNDEyOSAxNC41NDM0IDE1LjEzOTMgMTQuODI2OEMxNC44NjU4IDE1LjExMDIgMTQuNDE0MyAxNS4xMTgxIDE0LjEzMDkgMTQuODQ0NkwxMC4zNzUgMTEuMjE4OUw2LjYxOTEgMTQuODQ0NkM2LjMzNTczIDE1LjExODEgNS44ODQyNSAxNS4xMTAyIDUuNjEwNjkgMTQuODI2OEM1LjMzNzE0IDE0LjU0MzQgNS4zNDUxIDE0LjA5MiA1LjYyODQ4IDEzLjgxODRMOS4zNDgxNyAxMC4yMjc2TDUuNjI4NDggNi42MzY4OUM1LjM0NTEgNi4zNjMzNCA1LjMzNzE0IDUuOTExODYgNS42MTA2OSA1LjYyODQ4Wk0xNC42MDA2IDE0LjM1OEMxNC42MDA3IDE0LjM1ODEgMTQuNjAwNiAxNC4zNTc5IDE0LjYwMDYgMTQuMzU4WiIgZmlsbD0iI0RGMzEzMyIvPjwvc3ZnPg==);

.osm-popup{

  &__railway{
    position: fixed;
    display: block;
    z-index: 1000;
    bottom: 2rem;
    max-width: 52rem;
    left: 12rem;
    @media(max-width: 768px) {
      left: 0rem;
    }
  }

  &__wrapper{
    max-width: 52rem;
    padding-bottom: 1rem;
    position: relative;
    @media (max-width: 768px) {
      max-width: 100vw;
      padding: 0 1rem;
    }
  }

  &__close{
    display: block;
    width: 1.312rem;
    height: 1.312rem;
    position: absolute;
    z-index: 1;
    right: -.5rem;
    top: -1rem;

    &:before{
      content: $icn-cross;
      display: inline-block;
    }
  }

  &__left,
  &__right{
    display: block;
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    margin-top: -2rem;
    @media(max-width: 768px) {
      display: none;
    }
  }

  &__left{
    left: -4.5rem;
    top: 50%;

    &:before{
      content: $icn-left;
      display: inline-block;
    }
  }

  &__right{
    right: -4.5rem;
    top: 50%;

    &:before{
      content: $icn-right;
      display: inline-block;
    }
  }
}

.popup-element{

  &__head{
    font-size: 1.2rem;
  }
}

osm-popup{
  display: block;
  background: #ffffff;
  border: solid #d8d7d7 1px;
  border-radius: .2rem;
  padding: 1.5rem 1rem;
  width: 17rem;

  margin: 0 .2rem;
  &:nth-last-child(1){
    margin-right: 0;
  }
  table{
    width: 100%;

    td{
      white-space: nowrap;

      &:nth-child(2),
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}
