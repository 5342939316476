@import "./variables";
@import "./mixins";

@keyframes barfiller {
  0% { width: 0; }
  15% { width: 20%; }
  25% { width: 40%; }
  50% { width: 50%; }
  70% { width: 80%; }
  100% { width: 98%; }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 1050;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__bar{
    position: fixed;
    height: 3px;
    width: 100%;
    top: 0;
    left: 0;
    background: $clr-red;
    animation: barfiller 1.5s;
    //box-shadow: 1px 0 4px rgba(53, 53, 53, 0.4);
    //margin: 0 0 0 $sidebar-width-min;
  }
}
.crm{
  .loader__bar{
    background: $clr-yellow;
  }
}
