%text-common{
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $linge-height-base;
}
%text{
  @extend %text-common;
  font-weight: normal;
}
%text-italic{
  @extend %text;
  font-style: italic;
}
%text-light{
  @extend %text-common;
  font-weight: 300;
}
%text-bold{
  @extend %text-common;
  font-weight: bold;
}
%text-bold-italic{
  @extend %text-bold;
  font-style: italic;
}

%text-sm-common{
  font-family: $font-family-base;
  font-size: $font-size-sm;
  line-height: $linge-height-sm;
}
%text-big-common{
  font-family: $font-family-base;
  font-size: $font-size-big;
  line-height: $linge-height-big;
}
%text-sm{
  @extend %text-sm-common;
  font-weight: 300;
}
%text-sm-bold{
  @extend %text-sm;
  font-weight: bold;
}
%text-big{
  @extend %text-big-common;
}

%h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: $font-family-base;
  font-weight: 300;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
}
%h2 {
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-family: $font-family-base;
  font-weight: 300;
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
}
%h3 {
  font-size: 1.125rem;
  font-family: $font-family-base;
  line-height: 1.313rem;
  font-weight: 300;
}

%input-label{
  @extend %text-sm;
  margin-left: 1rem;
  position: relative;
  margin-bottom: 0.1875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

%form-input{
  display: block;
  width: 100%;
  height: 2.375rem;
  border: none;
  padding: 0.2rem 0.875rem 0.16rem 0.875rem;
  outline: 0;
  background: transparent;
  @extend %text;
}

%button_sm{
  border-radius: 0.95rem !important;
  font-size: 0.8rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1rem;
}

%placeholder{
  color: $c-placeholder;
  font-weight: 300;
}

%panel{
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

%checkbox-toggler{
  content: "";
  display: block;
  border-radius: 100px;
  position: absolute;
  top: -2px;
  left: 0;
  margin-left: -3px;
  background: $checkbox-color-toggler;
  width: $checkbox-toggler;
  height: $checkbox-toggler;
  border: solid $checkbox-color-toggler-border 2px;
  transition: $transition-menu;
  font-size: 0.5rem;
  text-align: center;
  padding-top: 0.45rem;
  font-weight: bold;
}
