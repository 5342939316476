html,body{
  overflow: hidden;
}

section.header{
  padding-top: 1rem;
  padding-bottom: 0.3rem;
}

.header__backref{
  font-size: 1.8rem;
  margin-right: 1rem;
  margin-top: .45rem;
}

.dashboard-items__controller{
  margin-top: 2rem !important;

}
