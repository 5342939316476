@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$current: .05;
$max: 3;

@while $current <= $max {
  .indent__top_#{str-replace(inspect($current), '.', '_')}{
    margin-top: $current * 1rem;
  }
  $current: $current + .05;
}

.number__wrapper,
.price__wrapper{
  white-space: nowrap;

  &__devider{
    margin-right: 1px;
    letter-spacing: 0.8px;

    &_md{
      margin-right: 5px;
    }
  }

  &__number{
    &_bold{
      @extend %text-bold;

      &-red{
        @extend %text-bold;
        color: $clr-red-dark;
      }
    }
    &_through{
      text-decoration: line-through;
    }
    &_red{
      color: $clr-red-dark;
    }
  }

  &__currency{
    //margin-left: 1.5px;

    &_sm{
      @extend %text-sm;

      &-grey{
        @extend %text-sm;
        color: $c-dark-grey;
      }
    }
  }

  &_red{
    .price__wrapper__currency{
      @extend %text-sm;
      color: $c-dark-grey;
    }
  }
}

.price__wrapper {
  &__devider{
    margin-right: 2px;
  }
}


$rem: 1;

@while ($rem <= 3) {

  $col: 1;
  .collapce_#{inspect($rem)}rem{
    margin-left: ($rem / -2) * 1rem;
    margin-right: ($rem / -2) * 1rem;

    @while ($col <= 12) {
      >.col-xl-#{inspect($col)},
      >.col-lg-#{inspect($col)},
      >.col-md-#{inspect($col)},
      >.col-sm-#{inspect($col)}{
        padding-right: ($rem / 2) * 1rem;
        padding-left: ($rem / 2) * 1rem;
      }

      $col: $col + 1;
    }
  }

  $rem: $rem + 1;
}
