@import "../../common_styles/scss/variables";
.select2{
  &-selection{
    padding: 0.16rem 0.875rem 0 0.875rem !important;
    border-radius: 0 !important;

    &--multiple{
      padding: 0.16rem 0.875rem 0.16rem 0.875rem !important;
    }
    &--single{
      height: 2.5rem !important;
      border-radius: 0!important;
    }
    &__rendered{
      line-height: 1.5rem !important;
    }
  }
  &-container{
    border-radius: 0;
  }

  &-selection__arrow{
    top: 8px !important;
    right: 7px !important;

    &:before{
      content: "\e943";
      color: $clr-red-dark;
      border: 1px solid $border-gray-color;
      border-radius: 50%;
      font-size: 1rem;
      font-family: "Icons" !important;
    }

    b{
      display: none;
    }
  }
}

.select2-selection--single{
  .select2-selection__rendered{
    padding-left: 0 !important;
    padding-top: 0.125rem !important;
    height: 2rem;
    line-height: 2rem !important;
    border-radius: 0 !important;
  }
}

.select2-selection{
  border-radius: 0;
  border: 1px solid $c-grey2!important;
  padding: 8px 10px 7px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple{
  border: 1px solid $c-grey2;
  background: #FFFFFF;
}

.select2-container--default .select2-selection--multiple {
  .select2-selection__choice {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    background: var(--control-active-background);
    color: var(--clr-red-inside);
    padding: 0 22px 0 4px;
    border-radius: 3px 11px 11px 3px;
    margin-right: 3px;
    height: 1.375rem;
    font-size: 0.875rem;
    letter-spacing: -0.19px;
    line-height: 1.375rem;
  }

  .select2-selection__choice__remove {
    width: 1rem;
    height: 100%;
    position: absolute;
    right: -1px;
    font-weight: normal;
    top: -6px;
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-top: 6px;

    &:hover{
      opacity: .8;
    }
  }
}

.select2-dropdown {
  border: 1px solid $c-grey2!important;
  margin-top: -1px;
  border-radius: 3px;

  &.select2-dropdown--above{
    margin-top: 3px;
  }
}

.select2-container--focus{
  background: $c-yellow;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]{
  background: $c-yellow;
  color: $c-dark;
}

.select2-selection__choice__remove:hover{
  color: $c-yellow !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $c-placeholder;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: var(--clr-red);
  color: var(--clr-red-inside);

  span.icon2.icon2_metro, span.icon2.icon2_train {
    color: var(--clr-red-inside);
  }
}
