@import "./variables";

%icon2{
  font-family: 'Icons2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon2 {
  @extend %icon2;

  /* Modifier */
  &_sm{
    @extend %icon;
    font-size: .6rem!important;
    padding-top: 7px!important;
  }
  &_big{
    @extend %icon;
    font-size: 1.125rem!important;
  }
  &_md{
    font-size: 1.125rem;
  }
  &_line{
    position: relative;
    top: 3px;
  }
  &_grey:before{
    color: $c-grey2 !important;
  }


  /* Front icons */
  $icons_front: (
    backref-red: "\e900",
    Unique: "\e901",
    metro: "\e902",
    person: "\e906",
    train: "\e907",
    discount: "\e903",
    book: "\e904",
    check: "\e905",
    menu: "\e90d",
    circle: "\e910",
    copyDone: "\e911",
    copyStart: "\e912",
    backward: "\e913",
    help: "\e914",
    mark-gray: "\e915",
    mark-green: "\e916",
    mark-red: "\e917",
    home-o: "\e918",
    home: "\e919",
    office: "\e91a",
    library: "\e921",
  );

  @each $name, $value in $icons_front {
    &_#{$name}:before{
      content: $value;
    }
  }

  &.icon2_metro{
    font-size: 1.1rem;
    bottom: -0.15rem;
    position: relative;
    color: $clr-red;
  }

  &.icon2_train{
    font-size: 1.1rem;
    bottom: -0.15rem;
    position: relative;
    color: $clr-red;
  }
  &.icon2_discount{
    font-size: 0.6rem;
    color: inherit;
    position: absolute;
    top: 2px;
    right: 2px;
  }
  &.icon2_menu{
    &.active {
      &:before{
        content:url('/assets/images/mob-menu-active.svg');
      }
    }
  }
  &.icon2_menu{
    &.active {
      &:before{
        content:url('/assets/images/mob-menu-active.svg');
      }
    }
  }
  &.icon2_copyStart{
    &:before{
      font-size: 1.3rem;
    }
  }
  &.icon2_copyDone{
    &:before{
      color: #8fc41f;
      font-size: 1.3rem;
    }
  }

}
