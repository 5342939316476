/* Size */
$rem: 16;

$sidebar-width: 240px;
$sidebar-width-min: 48px;
$sidebar-width-min-front: 0px;

$width-sm: 768px;
$width-md: 992px;
$height-md: 740px;

$font-size-base: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-button: 0.875rem;
$font-size-big: 1.1rem;

$linge-height-base: 1.125rem;
$linge-height-sm: 1rem;
$linge-height-big: 1.4rem;

/* Z-index */
$z-content: 1;
$z-menu: 1020;
$z-navbar: 110;
$z-modal: 1024;
$z-login: 1022;

/* Media */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

/* Colors*/
$c-black: #000000;
$c-black2: #353535;
$c-white: #FFFFFF;
$c-white2: #F9F9F9;
$c-dark: rgba(0,0,0,0.8);
$c-red: #DE1D25;
$c-red2: #DE1D25;
$c-yellow: #FFF3DB;
$c-yellow-a: rgba(255,178,23,0.15);
$c-yellow2: #FFB217;
$c-orange: #E74920;
$c-green: #69B318;
$c-green-light: #caffb3;
$c-pink: #EB767B;
$c-grey: #E0DFDF;
$c-grey2: #D8D7D7;
$c-grey-a: rgba(0, 0, 0, 0.03);
$c-grey-a5: rgba(224, 223, 223, 0.5);
$c-dark-grey: rgba(0,0,0,0.55);
$c-light-grey: #bababa;
$c-blue: #768deb;

$c-normal: #EE1B24;
$c-checkbox: $c-red2;
$c-normal-h: #F96066;
$c-normal-a: #F8BDC0;
$c-sub: #E97378;

$c-select: #FF7000;
$c-select-a: #FDA967;

$c-disable: rgba(0,0,0,0.55);
$c-placeholder: #989797;

$c-akcent0: #e5e5e5;
$c-akcent1: #01A3FF;
$c-akcent2: #CDD101;
$c-akcent3: #B00098;
$c-akcent4: #01C8D3;
$c-akcent5: #ff970e;
$c-akcent6: #c4a408;
$c-akcent7: #6ecd05;

$c-menu-background: rgba(255,178,23,0.5);
$c-menu-main-background: #FFB217;
$c-menu-icon-background: rgba(255,178,23,0.5);
$c-menu-hover: rgba(222,29,37,0.25);

$c-table-background: rgba(255,178,23,0.1);
$c-table-border: $c-grey2;

$c-input-border: $c-grey2;

$c-kvartirogramma: $c-grey;
$c-kvartirogramma-balnk: $c-light-grey;

$c-kvartira: $c-white;
$c-empty: $c-akcent0;
$c-no-kvartira: $c-akcent4;
$c-sale: $c-akcent7;
$c-reserv: $c-akcent5;
$c-bron: $c-akcent6;
$c-sales: $c-red;

$link-color: $c-normal;

/* Fonts */
$font-family-sans-serif: 'Geometria', arial;
$font-family-serif: 'Geometria', arial;
$font-family-base: 'Geometria', arial;
$font-family-monospace: 'Geometria', arial;
$font-family-icon: 'Icons';
$font-family-icon-housing: 'Icons-Housing';

/* Bloks */
$transition-menu: all 400ms ease 0s;

/*
 * Buttons
 */
$btn-height: 2.5rem;
$btn-line-height: 2.38rem;
$btn-padding: 0 1rem;
$btn-border-radius: 1.25rem;
$btn-color-shadow-hover: $c-yellow2;

$btn-color: $c-black;
$btn-color-hover: $c-white;
$btn-color-background: $c-pink;
$btn-color-background-hover: $c-orange;
$btn-color-background-active: $c-pink;
$btn-color-border: $c-pink;
$btn-color-focus: $c-pink;
$btn-label: $c-dark;

$btn-main-color: $c-white;
$btn-main-color-hover: $c-white;
$btn-main-color-background: $c-red;
$btn-main-color-border: $c-red;
$btn-main-padding: $btn-padding;

$btn-sub-color: $c-white;
$btn-sub-color-hover: $c-white;
$btn-sub-color-background: $c-pink;
$btn-sub-color-border: $c-pink;
$btn-sub-padding: $btn-padding;

$btn-grey-color: $c-white;
$btn-grey-color-hover: $c-white;
$btn-grey-color-background: $c-dark-grey;
$btn-grey-color-border: $c-dark-grey;
$btn-grey-padding: $btn-padding;

/*
 * Panels
 */

$panel-color-background: $c-white;
$panel-color-border: $c-grey2;

$panel-border-width: 1px;
$panel-border-radius: 3px;


/*
 * Checkboxes
 */
$checkbox-width: 2.25rem;
$checkbox-height: 1.25rem;

$checkbox-border-color: $c-black2;
$checkbox-border-width: 2px;

$checkbox-color-background: $c-white;
$checkbox-color-background-disabled: $c-grey2;

$checkbox-color-toggler: $c-white;
$checkbox-color-toggler-border: $c-black2;
$checkbox-color-toggler-active: $c-normal;
$checkbox-color-toggler-disabled: $c-grey2;

$checkbox-toggler: 1.25rem;

/* CRM */
$navbar-height: 48px;

$c-navbar: $c-red;
$c-sidebar-crm: $c-red2;
$c-menu-crm-hover: rgba(222,29,37,0.25);




/* Custom colors */

:root {



  --clr-white: rgb(255, 255, 255); // #FFFFFF


  --clr-red-default: rgb(238, 27, 36); // #ee1b24
  --clr-red-original-default: rgb(255, 0, 0);
  --clr-red-light-default: rgb(231, 73, 32); // #e74920
  --clr-red-bright-default: rgb(220, 53, 69); // #dc3545
  --clr-red-dark-default: rgb(222, 29, 37); // #DE1D25
  --clr-red-darker-default: rgb(208, 2, 27); // #d0021b
  --clr-red-darkest-default: rgb(175, 13, 20); // #af0d14

  --clr-pink-default: rgb(235, 118, 123); // #eb767b
  --clr-pink-light-default: rgb(238, 168, 170); // #eea8aa
  --clr-pink-dark-default: rgb(223, 129, 127); // #DF817F
  --clr-pink-border-dark-default: rgb(211, 122, 120); // #D37A78


  --clr-yellow-inside: rgb(255, 255, 255); //#fff
  --clr-red-inside: rgb(255, 255, 255); //#fff

  --clr-yellow-default: rgb(255, 178, 23); //#ffb217
  --clr-yellow-opacity-default: rgba(255,178,23,.5);
  --clr-yellow-opacity-2x-default: rgba(255,178,23,.25);
  --clr-yellow-opacity-3x-default: rgba(255, 178, 23, 0.1);
  --clr-yellow-dark-default: rgb(239, 200, 124); // #E2BD75
  --clr-yellow-border-dark-default: rgb(226, 189, 117); // #EFC87C
  --clr-yellow-search-start-default: rgb(255, 199, 88); // #ffc758
  --clr-yellow-search-end-default: rgb(255, 214, 130); // #ffd682

  --clr-red: rgb(238, 27, 36); // #ee1b24
  --clr-red-original: rgb(255, 0, 0);
  --clr-red-light: rgb(231, 73, 32); // #e74920
  --clr-red-bright: rgb(220, 53, 69); // #dc3545
  --clr-red-dark: rgb(222, 29, 37); // #DE1D25
  --clr-red-darker: rgb(208, 2, 27); // #d0021b
  --clr-red-darkest: rgb(175, 13, 20); // #af0d14

  --clr-pink: rgb(235, 118, 123); // #eb767b
  --clr-pink-light: rgb(238, 168, 170); // #eea8aa
  --clr-pink-dark: rgb(223, 129, 127); // #DF817F
  --clr-pink-border-dark: rgb(211, 122, 120); // #D37A78

  --clr-yellow: rgb(255, 178, 23); //#ffb217
  --clr-yellow-opacity: rgba(255,178,23,.5);
  --clr-yellow-opacity-2x: rgba(255,178,23,.25);
  --clr-yellow-opacity-3x: rgba(255, 178, 23, 0.1);
  --clr-yellow-dark: rgb(239, 200, 124); // #E2BD75
  --clr-yellow-border-dark: rgb(226, 189, 117); // #EFC87C
  --clr-yellow-search-start: rgb(255, 199, 88); // #ffc758
  --clr-yellow-search-end: rgb(255, 214, 130); // #ffd682

  --clr-blue: rgb(0, 123, 255); // #007bff

  --clr-gray-light: rgb(249, 249, 249); // #F9F9F9
  --clr-gray-darklight: rgb(236, 236, 236); // #ececec
  --clr-gray-dark: rgb(219, 217, 215); // #dbd9d7
  --clr-gray-alt: rgb(224, 223, 223); // #D8D7D7 // #dee2e6
  --clr-gray-alt2: rgb(224, 223, 223); // #e0dfdf
  --clr-gray-alt3: rgba(0,0,0,.55);
  --clr-gray-alt4: rgba(0, 0, 0, 0.05);

  --clr-black: rgba(0,0,0,.8);
  --clr-black-soft: rgba(0,0,0,.3);
  --clr-black-total: rgb(0, 0, 0);
  --clr-black-light: rgb(33, 37, 41); // #353535
  --clr-black-lighter: rgb(53, 53, 53); // #353535

  --img-search: url('/assets_common/images/search_bg.jpg');

  --menu-black-color: var(--clr-black-total);
  --menu-black-hover-color: var(--clr-black);
  --menu-active-background: var(--clr-yellow-opacity);
  --menu-active-first-background: var(--clr-yellow);
  --menu-icon-background: var(--clr-yellow-opacity-2x);
  --menu-hover-background: var(--clr-yellow-opacity-2x);

  --text-gray-color: var(--clr-black);
  --text-main-color: var(--clr-black-light);
  --text-red-color: var(--clr-red-bright);

  --calen-pink-background: var(--clr-pink-dark);
  --calen-pink-border: var(--clr-pink-border-dark);

  --calen-yellow-background: var(--clr-yellow-dark);
  --calen-yellow-border: var(--clr-yellow-border-dark);

  --border-gray-color: var(--clr-gray-alt);
  --border-red-color: var(--clr-red);

  --icon-red-color: var(--clr-red);
  --icon-yellow-color: var(--clr-yellow);
  --icon-white-color: var(--clr-white);

  --li-dot-background: var(--clr-red-dark);

  --dropimage-background: var(--clr-gray-alt2);

  --step-background: var(--clr-gray-alt2);
  --step-light-background: var(--clr-gray-darklight);

  --plus-color-red: var(--clr-red-original);

  --tr-gray-background: var(--clr-gray-alt4);
  --tr-yellow-background: var(--clr-yellow-opacity-3x);

  --control-nonactive-background: var(--clr-white);
  --control-nonactive-border: var(--clr-black-lighter);
  --control-active-background: var(--clr-red-dark);
  --control-active-border: var(--clr-red-dark);
  --control-disabled-background: var(--clr-gray-alt);
  --control-disabled-border: var(--clr-gray-alt);

  --sidebar-red-background: var(--clr-red-dark);
  --sidebar-red-top-background: var(--clr-red-dark);
  --sidebar-gray-background: var(--clr-gray-dark);

  --header-red-background: var(--clr-red);

  --tab-active-background: var(--clr-red-dark);
  --tab-hovered-background: var(--clr-yellow);

  --link-new-color: var(--clr-red);
  --link-new-hovered-color: var(--clr-red-darkest);

  --footer-spn-background: var(--clr-red-dark);
  --footer-spn-color: var(--clr-white);

  --button-red-background: var(--clr-red-dark);
  --button-red-border: var(--clr-red-dark);
  --button-pink-background: var(--clr-pink);
  --button-pink-border: var(--clr-pink);
  --button-hovered-background: var(--clr-red-light);
  --button-active-background: var(--clr-yellow);
  --button-disabled-background: var(--clr-gray-alt3);
  --button-disabled-border: var(--clr-gray-alt3);

  --search-block-background: var(--clr-pink-light);
  --search-block-image: var(--img-search);

  --progress-sctive: var(--clr-blue);

  --close-color: var(--clr-black-total);


}

$clr-white: var(--clr-white);

$clr-red: var(--clr-red);
$clr-red-original: var(--clr-red-original);
$clr-red-light: var(--clr-red-light);
$clr-red-bright: var(--clr-red-bright);
$clr-red-dark: var(--clr-red-dark);
$clr-red-darker: var(--clr-red-darker);
$clr-red-darkest: var(--clr-red-darkest);

$clr-pink: var(--clr-pink);
$clr-pink-light: var(--clr-pink-light);
$clr-pink-dark: var(--clr-pink-dark);
$clr-pink-border-dark: var(--clr-pink-border-dark);

$clr-yellow-inside: var(--clr-yellow-inside);
$clr-red-inside: var(--clr-red-inside);


$clr-yellow: var(--clr-yellow);
$clr-yellow-opacity: var(--clr-yellow-opacity);
$clr-yellow-opacity-2x: var(--clr-yellow-opacity-2x);
$clr-yellow-opacity-3x: var(--clr-yellow-opacity-3x);
$clr-yellow-dark: var(--clr-yellow-dark);
$clr-yellow-border-dark: var(--clr-yellow-border-dark);

$clr-yellow-search-start: var(--clr-yellow-search-start);
$clr-yellow-search-end: var(--clr-yellow-search-end);


$clr-blue: var(--clr-blue);

$clr-gray-light: var(--clr-gray-light);
$clr-gray-darklight: var(--clr-gray-darklight);
$clr-gray-dark: var(--clr-gray-dark);
$clr-gray-alt: var(--clr-gray-alt);
$clr-gray-alt2: var(--clr-gray-alt2);
$clr-gray-alt3: var(--clr-gray-alt3);
$clr-gray-alt4: var(--clr-gray-alt4);

$clr-black: var(--clr-black);
$clr-black-soft: var(--clr-black-soft);
$clr-black-total: var(--clr-black-total);
$clr-black-light: var(--clr-black-light);
$clr-black-lighter: var(--clr-black-lighter);


/* Custom elements */
$menu-black-color: var(--menu-black-color);
$menu-black-hover-color: var(--menu-black-hover-color);
$menu-active-background: var(--menu-active-background);
$menu-active-first-background: var(--menu-active-first-background);
$menu-icon-background: var(--menu-icon-background);
$menu-hover-background: var(--menu-hover-background);

$text-gray-color: var(--text-gray-color);
$text-main-color: var(--text-main-color);
$text-red-color: var(--text-red-color);

$calen-pink-background: var(--calen-pink-background);
$calen-pink-border: var(--calen-pink-border);

$calen-yellow-background: var(--calen-yellow-background);
$calen-yellow-border: var(--calen-yellow-border);

$border-gray-color: var(--border-gray-color);
$border-red-color: var(--border-red-color);

$icon-red-color: var(--icon-red-color);
$icon-yellow-color: var(--icon-yellow-color);
$icon-white-color: var(--icon-white-color);

$li-dot-background: var(--li-dot-background);

$dropimage-background: var(--dropimage-background);

$step-background: var(--step-background);
$step-light-background: var(--step-light-background);

$plus-color-red: var(--plus-color-red);

$tr-gray-background: var(--tr-gray-background);
$tr-yellow-background: var(--tr-yellow-background);

$control-nonactive-background: var(--control-nonactive-background);
$control-nonactive-border: var(--control-nonactive-border);
$control-active-background: var(--control-active-background);
$control-active-border: var(--control-active-border);
$control-disabled-background: var(--control-disabled-background);
$control-disabled-border: var(--control-disabled-border);

$sidebar-red-background: var(--sidebar-red-background);
$sidebar-red-top-background: var(--sidebar-red-top-background);
$sidebar-gray-background: var(--sidebar-gray-background);

$header-red-background: var(--header-red-background);

$tab-active-background: var(--tab-active-background);
$tab-hovered-background: var(--tab-hovered-background);

$link-new-color: var(--link-new-color);
$link-new-hovered-color: var(--link-new-hovered-color);

$footer-spn-background: var(--footer-spn-background);
$footer-spn-color: var(--footer-spn-color);

$button-red-background: var(--button-red-background);
$button-red-border: var(--button-red-border);
$button-pink-background: var(--button-pink-background);
$button-pink-border: var(--button-pink-border);
$button-hovered-background: var(--button-hovered-background);
$button-active-background: var(--button-active-background);
$button-disabled-background: var(--button-disabled-background);
$button-disabled-border: var(--button-disabled-border);

$search-block-background: var(--search-block-background);
$search-block-image: var(--search-block-image);

$progress-sctive: var(--progress-sctive);

$close-color: var(--close-color);

