@import "../../common_styles/scss/variables";
.dsg-autocomplete{
  &__wrapper{
    position: relative;
  }

  &__list{
    position: absolute;
    z-index: 2;
    background: #FFF;
    left: 0;
    list-style: none;
    display: block;
    border: 1px solid $border-gray-color;
    border-radius: 3px;

    width: 100%;
    padding: 0;
    margin: 0;
    top: 63px;

    li{
      padding: .5rem 1rem;
      cursor: pointer;

      &:hover{
        background: #fda967;
      }

      p{
        margin-bottom: .3rem;
        color: #000;

        &.text-small{
          margin-bottom: 0;
          font-size: .85rem;
          color: #999;
        }
      }
    }
  }
}
