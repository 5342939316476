//@font-face {
//			font-family: "GothamProRegular";
//			src: url("../fonts/GothamProRegular/GothamProRegular.eot");
//			src: url("../fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
//			url("../fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
//			url("../fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
//			font-style: normal;
//			font-weight: normal;
//	}

@font-face {
  font-family: 'Icons';
  src:  url('../../common_styles/fonts/icons/icomoon-0.eot');
  src:  url('../../common_styles/fonts/icons/icomoon-0.eot#iefix') format('embedded-opentype'),
    url('../../common_styles/fonts/icons/icomoon-0.ttf') format('truetype'),
    url('../../common_styles/fonts/icons/icomoon-0.woff') format('woff'),
    url('../../common_styles/fonts/icons/icomoon-0.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Icons-Calendar';
  src:  url('../../common_styles/fonts/icomoon-calendar/icomoon-calendar.eot');
  src:  url('../../common_styles/fonts/icomoon-calendar/icomoon-calendar.eot#iefix') format('embedded-opentype'),
    url('../../common_styles/fonts/icomoon-calendar/icomoon-calendar.ttf') format('truetype'),
    url('../../common_styles/fonts/icomoon-calendar/icomoon-calendar.woff') format('woff'),
    url('../../common_styles/fonts/icomoon-calendar/icomoon-calendar.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*
 * Separate icons set 2
 * I have a troubles to load old icons set
 */

@font-face {
  font-family: 'Icons2';
  src:  url('../../common_styles/fonts/icons-2/icomoon.eot');
  src:  url('../../common_styles/fonts/icons-2/icomoon.eot#iefix') format('embedded-opentype'),
  url('../../common_styles/fonts/icons-2/icomoon.ttf') format('truetype'),
  url('../../common_styles/fonts/icons-2/icomoon.woff') format('woff'),
  url('../../common_styles/fonts/icons-2/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../../common_styles/fonts/geometria/35AEFE_0_0.eot');
  src: url('../../common_styles/fonts/geometria/35AEFE_0_0.eot?#iefix') format('embedded-opentype'),
  url('../../common_styles/fonts/geometria/35AEFE_0_0.woff2') format('woff2'),
  url('../../common_styles/fonts/geometria/35AEFE_0_0.woff') format('woff'),
  url('../../common_styles/fonts/geometria/35AEFE_0_0.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Geometria';
  src: url('../../common_styles/fonts/geometria/35AEFE_1_0.eot');
  src: url('../../common_styles/fonts/geometria/35AEFE_1_0.eot?#iefix') format('embedded-opentype'),
  url('../../common_styles/fonts/geometria/35AEFE_1_0.woff2') format('woff2'),
  url('../../common_styles/fonts/geometria/35AEFE_1_0.woff') format('woff'),
  url('../../common_styles/fonts/geometria/35AEFE_1_0.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Geometria';
  src: url('../../common_styles/fonts/geometria/35AEFE_2_0.eot');
  src: url('../../common_styles/fonts/geometria/35AEFE_2_0.eot?#iefix') format('embedded-opentype'),
  url('../../common_styles/fonts/geometria/35AEFE_2_0.woff2') format('woff2'),
  url('../../common_styles/fonts/geometria/35AEFE_2_0.woff') format('woff'),
  url('../../common_styles/fonts/geometria/35AEFE_2_0.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('../../common_styles/fonts/geometria/35AEFE_3_0.eot');
  src: url('../../common_styles/fonts/geometria/35AEFE_3_0.eot?#iefix') format('embedded-opentype'),
  url('../../common_styles/fonts/geometria/35AEFE_3_0.woff2') format('woff2'),
  url('../../common_styles/fonts/geometria/35AEFE_3_0.woff') format('woff'),
  url('../../common_styles/fonts/geometria/35AEFE_3_0.ttf') format('truetype');
}
