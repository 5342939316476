@keyframes ball-grid-pulse {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(0.5);
    opacity: 0.7
  }
  100% {
    transform: scale(1);
    opacity: 1
  }
}

.ball-grid-pulse {
  width: 57px
}

.ball-grid-pulse > div:nth-child(1) {
  animation-delay: .55s;
  animation-duration: .62s
}

.ball-grid-pulse > div:nth-child(2) {
  animation-delay: .5s;
  animation-duration: 1.39s
}

.ball-grid-pulse > div:nth-child(3) {
  animation-delay: .37s;
  animation-duration: 1.24s
}

.ball-grid-pulse > div:nth-child(4) {
  animation-delay: -.11s;
  animation-duration: 1.24s
}

.ball-grid-pulse > div:nth-child(5) {
  animation-delay: .73s;
  animation-duration: .96s
}

.ball-grid-pulse > div:nth-child(6) {
  animation-delay: .25s;
  animation-duration: .95s
}

.ball-grid-pulse > div:nth-child(7) {
  animation-delay: .4s;
  animation-duration: 1.18s
}

.ball-grid-pulse > div:nth-child(8) {
  animation-delay: .02s;
  animation-duration: 1.59s
}

.ball-grid-pulse > div:nth-child(9) {
  animation-delay: .16s;
  animation-duration: 1.58s
}

.ball-grid-pulse > div {
  background-color: var(--clr-red-dark);
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  animation-name: ball-grid-pulse;
  animation-iteration-count: infinite;
  animation-delay: 0
}
