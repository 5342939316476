body{
  height: auto;
}



@media(min-width: 769px){
  .map-block {
    #map-auto-height{
      height: 618px;
    }
    .dashboard {
      height: 705px;
    }
    .spoiler__wrapper{
      height: 720px;
    }
  }
}


#obj-search-tabs{
  padding: 1rem 0!important;
  border: 0;
}

@media (max-width: 768px) {
  .show-map {
    #map-auto-height {
      margin: 0 -1rem 0 !important;
      max-height: 600px !important;
    }
    .mobile_show__trigger[_ngcontent-c5] {
      top: 10rem;
    }
  }
}


#metro-map {
  .drag-scroll-wrapper{
    height: 500px !important;
    overflow: hidden!important;
    .drag-scroll-content {
      overflow: hidden!important;
    }
  }
}
