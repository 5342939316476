
.modal{
  &.client-chose{
    .modal-dialog{
      max-width: 900px!important;
    }
  }

  &.compares-list{
    .modal-dialog{
      //max-width: 1100px!important;
    }
  }

  &.favorites-list{
    .modal-dialog{
      max-width: 900px!important;
    }

    .favorites-list__footer{
      margin-top: 1rem;
      display: flex;
    }
  }
}

.facade-element__siblings{
  .form__group{
    margin-bottom: 0;
  }

  &.alignment-right{
    .form__group__dropdown{
      right: 0;
    }

  }
}

.select2{
  &.select2-container--open{
    @media (max-width: 768px) {
      // эти стили Андрюши ломают мультиселект на мобильной версии, в особенности в сафари iphone
      //position: fixed;
      //left: 0;
      //top: 0;
      //height: 3rem;
      //overflow: hidden;
      //z-index: 900;
      //.select2-selection{
      //  height: 3.5rem;
      //  ul{
      //    display: flex;
      //  }
      //}
    }
  }
}


.header{
  @media (max-width: 768px) {
    &__backref{
      display: none;
    }
  }
}


.mobile-sort-container{
  background: white;
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  z-index: 10000;
  &.active {
    bottom: 0%;
  }
}


.table {
  th{
    white-space: nowrap;
    .input{
      min-width: 3.5rem;
    }
  }
}

